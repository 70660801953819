<template>
  <component
    :is="component"
    :to="isLinkHidden ? undefined : path"
    class="a-stories"
    :class="{ seen }"
    :no-prefetch="isLinkHidden ? undefined : true"
    @click="onClick(path)"
  >
    <AImage
      :src="src"
      :mobile-src="mobileSrc"
      :from-public="imgFromPublic"
      with-retina
      width="148"
      height="220"
      with-webp
    />
    <div class="a-stories__overlay" />
  </component>
</template>

<script lang="ts" setup>
import { resolveComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useNavigate } from '@/composables/useNavigate'
import AImage from '@/components/atoms/Image/AImage.vue'

const props = defineProps({
  src: {
    type: String,
    required: true
  },
  mobileSrc: {
    type: String,
    default: null
  },
  seen: {
    type: Boolean,
    default: false
  },
  imgFromPublic: {
    type: Boolean,
    default: false
  },
  path: {
    type: String,
    default: ''
  }
})

const { getUrl } = useNavigate()
const router = useRouter()

const isLinkHidden = computed(() => props.path?.includes?.('_ll'))
const component = isLinkHidden.value ? 'div' : resolveComponent('NuxtLink')

function onClick (path?: string) {
  isLinkHidden.value && router.push(getUrl(path ?? ''))
}
</script>

<style lang="postcss">
.a-stories {
  position: relative;
  width: 9.75rem;
  padding: var(--spacer-5xs);
  border: var(--spacer-5xs) solid var(--primary);
  border-radius: var(--border-radius-lg);
  cursor: pointer;
  aspect-ratio: 156 / 228;
  mix-blend-mode: multiply;

  &.seen {
    border-color: var(--color-neutral-300);
  }

  & > picture {
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: var(--border-radius-base);
    transition: 0.3s linear;
  }

  & img {
    object-fit: cover;
    max-height: 100%;
    aspect-ratio: 148 / 220;
  }

  & > .a-stories__overlay {
    position: absolute;
    border-radius: var(--border-radius-base);
    background-color: var(--bg-shadow-base);
    opacity: 0;
    transition: opacity 0.3s linear;
    inset: 0.125rem;
  }

  @media (hover: hover) and (--screen-lg) {
    &:hover {
      & > picture {
        transform: scale(1.01);
      }

      & > .a-stories__overlay {
        opacity: 1;
      }
    }
  }

  @media (--screen-xs) {
    & img {
      aspect-ratio: 128 / 192;
    }
  }
}
</style>
